<template>
    <div class="container mt-4 mb-3 terms">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center">
                    Termos e Condições
                </h1>

                <h2 class="text-center">
                    Condições Gerais de Aluguer
                </h2>
                <h2 class="text-center">
                    Cláusula 1ª (Ambito do Contrato)
                </h2>
                <p>
                    O presente contrato é celebrado entre a K2GO Rent a Car, Lda., adiante designada por locadora, e o
                    cliente identificado na cláusula primeira das condições particulares, e adiante designado por
                    locatário,
                    aplicando-se as presentes cláusulas gerais e particulares constantes na frente e verso deste
                    contrato,
                    sem prejuízo de qualquer derrogação, alteração ou aditamento efetuado por escrito.
                </p>
                <h2 class="text-center">
                    Cláusula 2ª (Entrega e Devolução do Veículo)
                </h2>
                <p>1 - O veículo alugado é entregue ao locatário na data de assinatura do presente contrato.</p>
                <p>
                    2 - Depois de ter tido oportunidade de verificar, o locatário reconhece que o veículo entregue no
                    âmbito
                    do presente contrato se encontra em bom estado de funcionamento, equipado com todos os acessórios e
                    com
                    os respectivos pneus em boas condições, não apresentando quaisquer defeitos visíveis e ou
                    aparentes;
                </p>
                <p>
                    3 - O locatário obriga-se a manter o veículo em bom estado de conservação e limpeza,
                    comprometendo-se
                    a
                    devolve-lo à locadora juntamente com todos os documentos e acessórios referentes ao mesmo nas
                    condições
                    em que lhe foi entregue, na data prevista no termo do presente contrato.
                </p>
                <p>
                    4 - O veículo deverá ser devolvido no termo do presente contrato ou à data da sua resolução, a um
                    funcionário da locadora, nas instalações desta ou em local que pela mesma seja indicado ao
                    locatário.
                </p>
                <p>
                    5 - No caso do veículo ser devolvido em local diferente do referido no número anterior o locatário
                    responde pelos decorrentes prejuízos causados à locadora.
                </p>
                <p>
                    6 - Salvo acordo expresso em contrário, o locatário obriga-se a devolver o veículo nas instalações
                    da
                    locadora onde o mesmo foi entregue e dentro das horas de expediente, ou seja, das 9h00 às 13h00 e
                    das
                    14h30 às 19h30.
                </p>
                <p>
                    7-A não devolução do veículo na data prevista no contrato constitui o locatário na obrigação de
                    ressarcir a locadora por todas as perdas ou danos incluindo furto, roubo ou abuso de confiança na
                    utilização do veículo.
                </p>
                <p>
                    8 - O atraso na restituição do veículo constitui o locatário na obrigação de pagar à locadora, a
                    título
                    de cláusula penal, por cada dia, inteiro ou fracção, uma quantia calculada com base no triplo da
                    tarifa
                    diária praticada pela locadora, para o veículo objecto do contrato.
                </p>
                <p>
                    9 - Apresentando o veículo, aquando da devolução, danos contrários ao seu uso prudente e normal, o
                    locatário deverá indemnizar a locadora pelo custo da reparação de tais danos.
                </p>
                <p>
                    10 - O locatário é responsável pelo pagamento dos danos causados nas partes superior, inferior e
                    interior do veículo desde que não haja colisão.
                </p>
                <p>
                    11 - Sem prejuízo do disposto nos números anteriores, no caso do aluguer de veículos de mercadorias,
                    o
                    locatário é responsável por todos os danos causados nas partes superior e inferior da carroçaria do
                    veículo, mesmo que estes sejam provocados pelo embate em árvores, varandas, pontes ou outros
                    obstáculos.
                    Não responderá, porém, pelos danos que não lhe sejam imputáveis, nem seja imputáveis a terceiro a
                    quem,
                    em contravenção ao acordado, tenha permitido a utilização do veículo.
                </p>
                <p>
                    12 - O presente contrato considerar-se-á automaticamente resolvido, sem necessidade de recurso à via
                    judicial, se o veículo que constitui o seu objecto for utilizado em condições que constituam
                    violação
                    das cláusulas do presente contrato.
                </p>
                <p>
                    13 - No caso referido no número anterior, para além da resolução automática do contrato, a locadora
                    reserva-se o direito de recuperar o veículo, a qualquer momento, sem necessidade de aviso prévio,
                    sendo
                    os encargos decorrentes da recuperação de única e inteira responsabilidade do locatário.
                </p>
                <h2 class="text-center">
                    Cláusula 3ª (Utilização do veículo)
                </h2>
                <p>
                    1 - O locatário não pode efectuar no veículo quaisquer modificações ou alterações, nem nele instalar
                    acessórios ou apor menções publicitárias ou comerciais sem prévia autorização por escrito da
                    locadora,
                    sob pena de ser considerado um possuidor de má-fé, nos termos do artigo 1275º do Código Civil.
                </p>
                <p>
                    2 - O locatário compromete-se, desde já, a não permitir que o veículo seja conduzido por pessoas que
                    não
                    estejam identificadas no presente contrato ou em documento anexo ao mesmo.
                </p>
                <p>
                    3 - O locatário só pode utilizar o veículo objecto do presente contrato dentro do território
                    português,
                    salvo se tiver autorização expressa da locadora.
                </p>
                <p>
                    4 - O locatário compromete-se a não utilizar ou a não permitir o uso do veículo nas seguintes
                    situações:
                </p>
                <ol type="a" style="margin-left: 40px">
                    <li>
                        Para efectuar transporte público de passageiros ou mercadorias ou outro a troco de qualquer
                        compensação ou remuneração;
                    </li>
                    <li>
                        Para utilização do veículo em provas desportivas ou treinos, quer estas sejam oficiais ou não;
                    </li>
                    <li>
                        Para transporte de mercadorias com violação dos regulamentos alfandegários ou fiscais ou que
                        por
                        qualquer outro motivo tal conduta seja ilegal;
                    </li>
                    <li>
                        Para empurrar ou puxar qualquer veículo ou reboque;
                    </li>
                    <li>
                        Por qualquer pessoa sob influência de álcool, narcóticos ou estupefacientes;
                    </li>
                    <li>
                        Para transporte de passageiros ou mercadorias em violação das características do veículo
                        constantes
                        do livrete / Documento Unico do mesmo;
                    </li>
                </ol>
                <p>
                    5 - 0 locatário fica, desde já, impedido de sublocar, emprestar ou ceder, total ou parcialmente, por
                    qualquer forma ou negócio, os direitos emergentes deste contrato, sem prévia autorização expressa da
                    locadora.
                </p>
                <p>
                    6- O locatário obriga-se a fechar e trancar devidamente o veículo, não deixando no seu interior os
                    documentos referentes ao mesmo ou quaisquer outros objetos suscetíveis de provocar furto, roubo ou
                    danos
                    no veículo.
                </p>
                <p>
                    7 - A perda ou destruição, total ou parcial, da documentação do veículo constituem o locatário na
                    obrigação de indemnizar a locadora pelos prejuízos inerentes, nomeadamente pelas despesas
                    decorrentes da
                    emissão de segundas vias, incluindo despesas administrativas por parte da locadora.
                </p>
                <h2 class="text-center">
                    Cláusula 4ª (Prolongamento do Aluguer)
                </h2>
                <p>
                    1 - O contrato de aluguer termina no dia fixado nas cláusulas particulares.
                </p>
                <p>
                    2 - Se o locatáro desejar prolongar o período de aluguer deverá dirigir-se às instalações da
                    locadora
                    com antecedência mínima de 24 horas e obter novo contrato, prolongando, deste modo, o período de
                    aluguer.
                </p>
                <p>
                    3 - O referido prolongamento ficará sempre sujeito a aprovação da locadora;
                </p>
                <p>
                    4 - Encontrando-se o contrato sujeito a renovações automáticas a falta de pagamento de qualquer das
                    prestações / mensalidades possibilita a imediata denúncia do contrato por parte da locadora.
                </p>
                <p>
                    5 - Caso a locadora não aceite prolongar o contrato, o locatário obriga-se a entregar o veículo na
                    data
                    anteriormente acordada.
                </p>
                <h2 class="text-center">
                    Cláusula 5ª (Manutenção e Reparação do Veículo)
                </h2>
                <p>
                    1 - Caso se aperceba da existência de algum problema mecânico no veículo o locatário compromete-se a
                    imobilizá-lo imediatamente e a contactar a locadora, dando-lhe conhecimento da ocorrência.
                </p>
                <p>
                    2- No caso de o veículo ficar imobilizado, devido a avaria mecânica, as reparações só poderão ser
                    efectuadas mediante acordo prévio e escrito, da locadora e de acordo com as instruções transmitidas.
                </p>
                <p>
                    3 - Quaisquer despesas de reboque, dentro ou fora do país, devido a má utilização do veículo serão
                    sempre de responsabilidade do locatário.
                </p>
                <h2 class="text-center">
                    Cláusula 6ª (Seguros)
                </h2>
                <p>
                    1- O locatário pode contratar os seguintes seguros:
                </p>
                <ol type="a" style="margin-left: 40px">
                    <li>
                        Super CDW - Abrange todos os danos causados na viatura e roubo total ou parcial da mesma, não
                        estando
                        o locatário sujeito ao pagamento de qualquer franquia;
                    </li>
                    <li>
                        CDW - Abrange todos os danos causados na viatura e roubo total ou parcial da mesma, estando o
                        locatário sujeito ao pagamento de uma franquia variável em função do tipo de veículo e constante
                        da
                        tabela anexa ao presente contrato, a qual faz parte integrante do mesmo;
                    </li>
                    <li>
                        TW - Abrange o furto e roubo da viatura;
                    </li>
                    <li>
                        PAI - Abrange Acidentes pessoais do condutor e ou ocupantes do veículo, cujo montantes máximos
                        são
                        de
                        500,00€ no caso de doença ou internamento e de 5.000,00€ no caso de morte ou invalidez;
                    </li>
                </ol>
                <p>
                    2 - O locatário obriga-se, em caso de acidente, a ter os seguintes procedimentos:
                </p>
                <ol type="a" style="margin-left: 40px">
                    <li>
                        Participar à locadora e às autoridades policiais todo e qualquer acidente, furto, roubo ou
                        quaisquer
                        outros sinistros, no prazo máximo de 24 horas;
                    </li>
                    <li>
                        Obter os nomes e endereços das pessoas envolvidas e testemunhas;
                    </li>
                    <li>
                        Não abandonar o veículo sem tomar as medidas adequadas com vista à protecção e salvaguarda do
                        mesmo;
                    </li>
                    <li>
                        Não assumir qualquer responsabilidade ou declarar-se culpado no caso de acidentes, que possam
                        implicar responsabilidade da locadora;
                    </li>
                    <li>
                        Telefonar imediatamente à locadora fornecendo-lhe um relatório detalhado do acidente incluindo
                        auto
                        de acidente levantado pelas autoridades policiais.
                    </li>
                </ol>
                <p>
                    3 - Em caso de acidente, furto ou roubo, o locatário é responsável por uma franquia referente aos
                    danos
                    causados na viatura, até ao montante fixado nas tarifas em vigor à data da celebração do presente
                    contrato, cujas tabelas se encontram em anexo ao presente contrato fazendo parte integrante do
                    mesmo.
                </p>
                <p>
                    4 - Essa franquia pode ser anulada mediante a aceitação prévia do seguro Super CDW, consoante a
                    categoria do veículo alugado.
                </p>
                <p>
                    5 - O locatário não será responsável pelas perdas ou danos causados no veículo se previamente tiver
                    contratado com a locadora o pagamento do seguro Super CDW (Danos na viatura e roubo total ou parcial
                    da
                    viatura, com dispensa de franquia), CDW (Danos na viatura e roubo total ou parcial da viatura) sendo
                    neste caso apenas responsável pelo pagamento da franquia obrigatória e insuprível em vigor a cada
                    momento e constante da tarifa de aluguer.
                </p>
                <p>
                    6 - Apenas o locatário e/ou os condutores indicados no presente contrato de aluguer poderão usufruir
                    da
                    cobertura dos seguros Super CDW, CDW e PAI.
                </p>
                <p>
                    7 - Mesmo no caso de o locatário subscrever o seguro Super CDW, todos os danos decorrentes da má
                    utilização do veículo, serão da sua exclusiva responsabilidade.
                </p>
                <p>
                    8 - Em caso de acidente devido a excesso de velocidade, negligência, condução sob influência de
                    álcool,
                    produtos estupefacientes ou consumo de qualquer produto que diminua a capacidade de condução, será o
                    locatário responsável pela totalidade das despesas da reparação e indemnização correspondente ao
                    tempo
                    de paralisação do veículo acidentado, mesmo que haja sido contratado o seguro Super CDW.
                </p>
                <p>
                    9 - O veículo apenas estará coberto por seguro durante o período acordado no contrato de aluguer,
                    excepto se houver prolongamento do contrato nos termos das presentes condições gerais, declinando
                    desde
                    já a locadora toda e qualquer responsabilidade pelos acidentes causados ou que possam vir a ser
                    causados
                    pelo locatário para além do tempo acordado no presente contrato, sendo este o único e exclusivo
                    responsável pelos mesmos.
                </p>


                <h2 class="text-center">
                    Cláusula 7ª (Pagamentos)
                </h2>
                <p>
                    1- O locatário obriga-se, expressamente, a pagar à locadora todas as importâncias devidas e
                    decorrentes
                    da celebração do presente contrato, logo que lhe sejam solicitadas, nomeadamente:
                </p>
                <ol type="a" style="margin-left: 40px">
                    <li>
                        O preço devido pelo aluguer do veículo, em função do período de aluguer e respectiva
                        quilometragem
                        calculada de acordo com a tarifa constante das condições particulares do presente contrato;
                    </li>
                    <li>
                        Todos e quaisquer encargos referentes à supressão de franquia suprivel, seguro de acidentes
                        pessoais,
                        seguro de choque, colisão e capotamento, seguro de roubo e quaisquer outras despesas aplicáveis
                        em
                        conformidade com a tarifa ou encargos constantes das condições particulares do presente
                        contrato;
                    </li>
                    <li>
                        Todos os impostos e taxas incidentes sobre o aluguer do veículo automóvel ou o montante fixado
                        pela
                        locadora para reembolso desses impostos;
                    </li>
                    <li>
                        Os custos suportados pela locadora emergentes da cobrança coerciva de pagamentos em dívida pelo
                        locatário e decorrentes do presente contrato, a título de custas de parte e nos termos da
                        legislação
                        processual aplicável;
                    </li>
                </ol>
                <p>
                    2 - Toda e qualquer fatura não paga na data do vencimento será acrescida de juros de mora à taxa
                    máxima
                    dos juros comerciais legalmente permitida;
                </p>
                <p>
                    3 - Em caso de acidente o locatário pagará, a título de comissao de despesas com o respectivo
                    processo,
                    100€ (cem euros).
                </p>
                <p>
                    4 - O locatário, para garantia do cumprimento das obrigações decorrentes do presente contrato,
                    prestará
                    caução pelo montante referido nas condições particulares, sendo esta preferencialmente utilizada
                    para
                    pagamento de cláusulas penais, juros de mora, despesas e indemnizações.
                </p>
                <p>
                    5 - A referida caução é prestada por via de cartão de crédito ou outro meio de pagamento acordado.
                </p>
                <p>
                    6 - O locatário autoriza expressamente a locadora a preencher e debitar no referido cartão de
                    crédito
                    ou
                    outro meio de pagamento as importâncias devidas.
                </p>
                <p>
                    7 - A locadora, apesar do disposto no número anterior, pode exigir, ainda, que o locatário apresente
                    um
                    ou mais fiadores;
                </p>
                <p>
                    8 - O(s) fiador(es) subscritor(es) do presente contrato, identificado(s) nas condições particulares
                    assume(m) a obrigação de principal(ais) pagador(es), garante(m) e responde(m) solidariamente por
                    todas
                    as obrigações decorrentes do presente contrato.
                </p>
                <p>
                    9 - Os fiadores abdicam desde já do benefício da excussão previsto no artigo 638º do Código
                    Civil.
                </p>


                <h2 class="text-center">
                    Cláusula 8ª (Dados Pessoais)
                </h2>
                <p>
                    1 - Os dados pessoais do locatário e condutor(es) do veículo são de fornecimento obrigatório para
                    celebração do presente contrato e destinam-se à identificação dos locatários e ou condutores dos
                    veículos alugados, não lhe podendo ser dado outro destino ou utilização, exceto consentimento
                    expresso
                    dos respetivos titulares;
                </p>
                <p>
                    2 - Face ao disposto no artigo 6.º da Lei n.º 67/98, de 28 de Outubro, entende-se que o locatário,
                    em
                    caso de incumprimento do contrato, autoriza que a locadora comunique os seus dados pessoais à ARAC —
                    Associação dos Industriais de Aluguer de Automóveis sem Condutor, para inclusão em base de dados de
                    clientes incumpridores e posterior divulgação pelas empresas associadas, se não assinalar a sua
                    vontade
                    em sentido contrário no espaço próprio.
                </p>
                <p>
                    3 - A locadora é a entidade responsável pelo tratamento informático dos dados pessoais fornecidos no
                    âmbito do presente contrato.
                </p>
                <p>▭ Declaro não autorizar a inclusão dos meus dados pessoais numa lista de clientes incumpridores.</p>
                <h2 class="text-center">
                    Cláusula 9ª (Infracções)
                </h2>
                <p>
                    1-O locatário obriga-se a restituir à locadora os valores de quaisquer coimas que esta tenha pago em
                    consequência de condutas ilícitas praticadas durante a vigência do contrato;
                </p>
                <p>
                    2 - O locatário pagará ainda uma taxa para custear as despesas administrativas em que a locadora
                    tenha
                    incorrido relativamente a qualquer coima aplicada nos termos do número anterior.
                </p>
                <p>
                    3 - No caso de o locador ser notificado, em consequência de contra-ordenação ou conduta ilícita
                    praticada pelo locatário, unicamente para identificar o mesmo, este obriga-se a pagar, a título de
                    despesas administrativas, o montante de 20€ (vinte euros) pela informação prestada àquelas
                    entidades.
                </p>
                <h2 class="text-center">
                    Cláusula 10ª (Litígios Judiciais e Extrajudiciais e Convenção de Domicílio)
                </h2>
                <p>
                    1 - A parte vencida suportará as despesas derivadas de tais litígios, nos termos da legislação
                    processual aplicável;
                </p>
                <p>
                    2 - Para todos os efeitos legais, nomeadamente para efeitos de citações ou notificações, judiciais
                    ou
                    extrajudiciais, as partes convencionam como domicílios as moradas indicadas neste contrato.
                </p>
                <h2 class="text-center">
                    Cláusula 11ª (Informação e Esclarecimentos)
                </h2>
                <p>1 - Quando solicitada / aplicável é disponibilizada uma versão em inglês destas condições gerais.</p>
                <p>
                    2 - Com a subscrição do contrato, o locatário expressamente reconhece que todas as cláusulas, gerais
                    e
                    particulares, constantes do presente contrato lhe foram atempada e expressamente comunicadas e
                    explicadas e que o mesmo ficou ciente das mesmas.
                </p>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name       : 'TermsPage',
        components : {},
        data       : function () {
            return {};
        }
    };
</script>
